import {BusinessPartyModel, ION_AS_ID_BY_TYPES} from './declarations';
import {ION_AS_ID_BPTYPES, SourceProductType} from '@matchsource/models/source';

export const formatIonDcId = (bp: BusinessPartyModel, sourceProductType: SourceProductType): string => {
  return bp.ion && ION_AS_ID_BY_TYPES.includes(bp.type) ? bp.ion : formatDcId(bp.identifier, sourceProductType);
};

export const formatDcId = (identifier: string, type: string) => {
  if (!identifier || type !== SourceProductType.Nmdp) {
    return identifier;
  }

  return identifier.padStart(3, '0');
};
export const getIonDCValue = (ts: BusinessPartyModel): string => {
  if (!ts) {
    return '';
  }
  if (ts.ion && ION_AS_ID_BPTYPES.includes(ts.type)) {
    return ts.ion;
  }
  return ts.identifier;
};
